<template>
  <div class="feedback_container overflow-y-auto">
    <div class="header">
      <span class="title_feedback"> {{ $t('feedback.question_offer') }} </span>
      <svg
        @click="$emit('close')"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 1.61143L14.3886 0L8 6.38857L1.61143 0L0 1.61143L6.38857 8L0 14.3886L1.61143 16L8 9.61143L14.3886 16L16 14.3886L9.61143 8L16 1.61143Z"
          fill="#0071C5"
        />
      </svg>
    </div>
    <transition name="">
      <div v-loading="loading" key="1" v-if="!isfeedSent" class="content">
        <span>
          {{ $t('feedback.description') }}
        </span>
        <div class="icons_container">
          <div
            @click="handleRatingLevel(index)"
            v-for="(img, index) in images"
            :key="index"
            class="icon"
          >
            <img
              :src="
                require(`@/components/map/tools/images/${
                  img.isActive ? img.active : img.notActive
                }.png`)
              "
              alt=""
            />
          </div>
        </div>
        <div class="type_container">
          <span
            :class="[
              { extra: index === 2 },
              { type_active: index === currentType }
            ]"
            v-for="(el, index) in feedback_type"
            :key="index"
            @click="handlType(el.key, index)"
          >
            {{ el.value }}</span
          >

          <!-- <span class="extra type_active">Другое</span> -->
        </div>
        <div class="comment_container">
          <span class="label"
            >{{ $t('feedback.yours') }}<span class="star">*</span></span
          >
          <div class="input_container">
            <el-input
              v-model="feedback.message"
              :rows="6"
              type="textarea"
              :class="[isEmpty ? 'input-error' : '']"
            ></el-input>
            <transition name="el-zoom-in-top">
              <span
                v-if="isEmpty"
                class="text-notify text-xs top-full left-0 absolute"
                >{{ $t('feedback.fill_area') }}
              </span>
            </transition>
          </div>
        </div>
        <div class="btn_container">
          <button @click="submit" class="submit_btn">
            {{ $t('feedback.send') }}
          </button>
        </div>
      </div>

      <div v-else key="2" class="feedsent_container">
        <img src="./images/smile.png" alt="" />
        <span> {{ $t('feedback.message_send') }} </span>
      </div>
    </transition>
  </div>
</template>
<script>
import { feedbackApi } from '@/api'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      isfeedSent: false,
      currentType: 0,
      isEmpty: false,
      feedback: {
        rating: 4,
        type: { key: 'proposal' },
        message: ''
      },
      images: [
        { isActive: false, active: '1', notActive: 'opacity1' },
        { isActive: false, active: '2', notActive: 'opacity2' },
        { isActive: false, active: '3', notActive: 'opacity3' },
        { isActive: true, active: '4', notActive: 'opacity4' },
        { isActive: false, active: '5', notActive: 'opacity5' }
      ]
    }
  },
  watch: {
    'feedback.message': function (val) {
      val.length ? (this.isEmpty = false) : (this.isEmpty = true)
    }
  },
  computed: {
    ...mapState('properties', {
      feedback_type: (state) => state.feedback_type
    })
  },
  methods: {
    submit() {
      if (!this.feedback.message.length) {
        this.isEmpty = true
      } else {
        const self = this
        this.loading = true
        feedbackApi.sendFeedback(
          this.feedback,
          (response) => {
            setTimeout(() => {
              self.loading = false
              this.isfeedSent = true
            }, 800)
          },
          (err) => {
            console.log('error submiting feedback', err)
          }
        )
        setTimeout(() => {
          this.$emit('close')
        }, 3500)
      }
    },
    handlType(element, index) {
      this.currentType = index
      this.feedback.type.key = element
    },
    changeColor(index) {
      this.images[index].isActive = !this.images[index].isActive
    },
    handleRatingLevel(rating) {
      this.images.forEach((img) => (img.isActive = false))
      this.feedback.rating = rating + 1
      this.images[rating].isActive = true
    }
  }
}
</script>
<style lang="stylus" scoped>
.input_container
  position: relative
  margin-top:10px
.icons_container
  justify-content center
  align-items center
  display flex

  & .icon
    margin: 30px 10px
    cursor: pointer
.feedsent_container
    height 630px
    padding-bottom: 30px
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    & img
      width:80px
      height:80px
      margin-bottom: 30px

.feedback_container
  max-height 80vh
  width 680px
  position absolute
  left 30%
  top 10%
  z-index  10
  padding 20px
  border-radius  10px
  font-family  'Source Sans Pro'
  font-style  normal
  font-weight  600
  font-size  20px
  line-height  25px
  background  white

  & .header
    border-bottom 1px solid #E6EAEE
    color  #0071C5
    width 100%
    height: 50px
    display flex
    justify-content space-between
    & svg
      cursor pointer
  & .content
    margin-top 30px
    text-align: center
    font-size: 16px
    color : #333333
    & .icons_container
      border-bottom 1px solid #E6EAEE
  .type_container
    margin-top 30px
    display flex
    align-items: center
    justify-content: center
    & span
      padding:20px
      padding: 20px 70px 20px 70px
      cursor: pointer
      border:  1px solid #D1DBE4
      border-right: none
      font-family: 'Lato'
      font-style: normal
      font-weight: 600
      font-size: 14px
      line-height: 16px
      color: #677785

  .extra
        border-right : 1px solid #D1DBE4 !important
  .type_active
        color: #0071C5 !important
        border:  2px solid #0071C5 !important
        transition: border-color 450ms linear
        // transition: color 250ms linear
  .comment_container
    margin 30px 10px 0 10px

    text-align: left
    & .label
      margin-top:20px
      color :#91B4E7
      padding: 10px 0
      & .star
        color red


  .btn_container
    display flex
    align-items center
    justify-content: center
    margin-top 30px
  .submit_btn{
    padding: 14px 70px
    border-radius: 5px
    background : #5477A9
    color: #FFFFFF
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
  fade-enter-active,
.fade-leave-active {
    transition: opacity .5s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}
</style>
